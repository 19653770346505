import type { DocumentPdfViewerDialogProperties } from "~/business-areas/document/document.model";

export function useDocumentPdfViewer() {
  const dialog = useDialog("DocumentPdfViewer");

  function open(properties: DocumentPdfViewerDialogProperties) {
    return dialog.open(properties);
  }

  return { open };
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AdobeDC: any;
  }
}

export function useAdobePDFEmbedApi() {
  const {
    public: {
      adobe: {
        pdfEmbed: { apiKey },
      },
    },
  } = useRuntimeConfig();

  function read(properties: DocumentPdfViewerDialogProperties) {
    const { onLoaded } = useScript(
      "https://acrobatservices.adobe.com/view-sdk/viewer.js",
      {
        async use() {
          if (!window.AdobeDC) {
            await new Promise((resolve) => {
              document.addEventListener("adobe_dc_view_sdk.ready", resolve);
            });
          }

          return { AdobeDC: window.AdobeDC };
        },
      },
    );

    onMounted(async () => {
      await nextTick();

      onLoaded(async () => {
        const adobeDCView = new window.AdobeDC.View({
          clientId: apiKey,
          divId: "viewer",
        });

        /*
        One known issue of Adobe PDF embed API is that even if printing UI is disabled
        with showPrintPDF setting set to false, user is still able to print the document with cmd+P keyboard shortcut.
        One solution, found here : https://community.adobe.com/t5/acrobat-services-api-discussions/disabled-printing-but-ctrl-p-still-prints/td-p/12019385
        Just reload the page when the user tries to print the PDF :)
         */
        adobeDCView.registerCallback(
          window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          function (event: any) {
            if (event.type === "DOCUMENT_PRINT") {
              window.location.reload();
            }
          },
          {
            enablePDFAnalytics: true,
          },
        );

        adobeDCView.previewFile(
          {
            content: { location: { url: properties.url } },
            metaData: { fileName: properties.title },
          },
          {
            showDownloadPDF: false,
            showPrintPDF: false,
            showThumbnails: true,
            enableTextSelection: false,
            showAnnotationTools: false,
            defaultViewMode: "FIT_WIDTH",
          },
        );
      });
    });
  }

  return {
    read,
  };
}
